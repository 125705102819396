import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="原則" mdxType="SEO" />
    <PageTitle title="原則" enTitle="Principles" mdxType="PageTitle" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/53ba45e8922a226d89b0cac661125929/21b4d/principles-index.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA+klEQVQoz6WRvUoDQRSF8w6KhS/gS1gLdvaW2omNIJraxk40YBWwsIgQ8Q+DrohYWS2WwQ3iuLCNf5lhdYdRZ+YTV1IEQsDd01w4l/txLqfCAHnv86mUIo5jkiRBCIHWum8/SJVhQCkl4lHQvmsTdaLywCzL0B+ar9SQqhRjTDHgr5xz+by6vWauvsD900OfXyihkpLN3W0ml6apH+3knnX2/wl7KZr7TY5bDW7CQ7ovb8UTOv93FEUd1k+nWD4f4SBcAwceV6Ll15RaMMtKMEbtZJ5P812uZaMtW5czrJ6Nshds0H1+Lw7svV1tLDJRHecibGGNzf1hwB+Qo/z3Z9y/NgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/53ba45e8922a226d89b0cac661125929/2aaaf/principles-index.webp 160w", "/static/53ba45e8922a226d89b0cac661125929/85e47/principles-index.webp 320w", "/static/53ba45e8922a226d89b0cac661125929/75198/principles-index.webp 640w", "/static/53ba45e8922a226d89b0cac661125929/691bc/principles-index.webp 960w", "/static/53ba45e8922a226d89b0cac661125929/223e5/principles-index.webp 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/53ba45e8922a226d89b0cac661125929/69538/principles-index.png 160w", "/static/53ba45e8922a226d89b0cac661125929/72799/principles-index.png 320w", "/static/53ba45e8922a226d89b0cac661125929/6af66/principles-index.png 640w", "/static/53ba45e8922a226d89b0cac661125929/d9199/principles-index.png 960w", "/static/53ba45e8922a226d89b0cac661125929/21b4d/principles-index.png 1280w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/53ba45e8922a226d89b0cac661125929/6af66/principles-index.png",
              "alt": "「Principles」と書かれた吹き出しが、右手でスマートフォンを持っている人から出ているイラスト",
              "title": "「Principles」と書かれた吹き出しが、右手でスマートフォンを持っている人から出ているイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Amebaのサービスを通じて、発信者や閲覧者といったユーザー、広告主や取引先といったパートナーとの接点において一貫した振る舞いをするためにどうあるべきかを「原則」として明記します。`}</p>
    <p>{`「原則」は私たちを強く縛るルールではありません。良い信頼関係を築くために守りたい「約束事」です。`}</p>
    <p>{`Amebaらしさを届けるためにどう設計するかのヒントとなる`}<a parentName="p" {...{
        "href": "/principles/design/"
      }}>{`デザイン原則`}</a>{`や、品質価値の基準として`}<a parentName="p" {...{
        "href": "/principles/accessibility/"
      }}>{`アクセシビリティ`}</a>{`と`}<a parentName="p" {...{
        "href": "/principles/performance"
      }}>{`パフォーマンス`}</a>{`、`}<a parentName="p" {...{
        "href": "/principles/contents"
      }}>{`コンテンツ`}</a>{`のガイドがあります。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      